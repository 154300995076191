
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Tenant from '@/store/entities/tenant'
import Modal from '../../../components/Modal.vue'
import BaseSwitch from '@/components/Inputs/BaseSwitch.vue'

@Component({
  components: { Modal, BaseSwitch }
})
export default class CreateTenant extends AbpBase {
  @Prop({ type: Boolean, default: false }) value: boolean
  tenant: Tenant = new Tenant()
  loading: Boolean = false
  async save() {
    if (this.checkFormValidity()) {
      this.loading = true
      await this.$store.dispatch({
        type: 'tenant/create',
        data: this.tenant
      })
      this.resetFields()
      this.$emit('save-success')
      this.$emit('input', false)
      this.loading = false
    }
  }
  checkFormValidity() {
    const valid = (this.$refs.tenantForm as any).checkValidity()
    return valid
  }
  cancel() {
    this.resetFields()
    this.$emit('input', false)
  }
  visibleChange(value: boolean) {
    if (!value) {
      this.$emit('input', value)
    }
  }
  resetFields() {
    this.tenant = new Tenant()
  }
  tenantRule = {
    name: {
      required: { required: true, min: 3 },
      message: this.L('FieldIsRequired', undefined, this.L('TenantName')),
      placeholder: this.L('TenantName'),
      trigger: 'blur'
    },
    tenancyName: {
      required: { required: true, min: 3 },
      message: this.L('FieldIsRequired', undefined, this.L('TenancyName')),
      placeholder: this.L('TenancyName'),
      trigger: 'blur'
    },
    databaseConnectionString: {
      required: { required: true, min: 3, max: 1024 },
      message: this.L('FieldIsRequired', undefined, this.L('DatabaseConnectionString')),
      placeholder: this.L('DatabaseConnectionString'),
      trigger: 'blur'
    },
    adminEmailAddress: {
      required: { required: true, min: 3, email: true },
      message: this.L('FieldIsRequired', undefined, this.L('AdminEmailAddress')),
      placeholder: this.L('AdminEmailAddress'),
      trigger: 'blur'
    }
  }
}
