<template>
    
    <a-form-item
        :label="label"
        :label-align="'left'"
    >
        <a-switch
            v-model="val"
            v-on="listeners"
        >
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
        </a-switch>
    </a-form-item>

</template>

<script>
export default {
    name: 'base-switch',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false,
            description: 'Whether the switch is disabled'
        }
    },
    computed: {
        listeners(){
            return {
            ...this.$listeners,
            change: this.onChange
            };
        },
        val: {
            get: function(){
                if(this.value){
                    return this.value;
                }
                return false;
            },
            set: function (newValue) {
            this.$emit("input", newValue);
            },    
        }
    },
    methods: {
        onChange(e){
            this.$emit("change", e);
        }
    }
}
</script>