
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import CreateTenant from './create-tenant.vue'
import EditTenant from './edit-tenant.vue'
import PeopleFind from "../../../components/People/people-find.vue";

class PageTenantRequest extends PageRequest {
  keyword: string = ''
  isActive: boolean = null
}

@Component({
  components: { CreateTenant, EditTenant, PeopleFind }
})
export default class Tenants extends AbpBase {

  edit() {
    this.editModalShow = true
  }

  pagerequest: PageTenantRequest = new PageTenantRequest()

  createModalShow: boolean = false
  editModalShow: boolean = false
  get list() {
    return this.$store.state.tenant.list
  }
  get loading() {
    return this.$store.state.tenant.loading
  }
  create() {
    this.createModalShow = true
  }
  editRow(item, index, button) {
    this.$store.commit('tenant/edit', item)
    this.edit()
  }
  isActiveChange(val: string) {
    if (val === 'Actived') {
      this.pagerequest.isActive = true
    } else if (val === 'NoActive') {
      this.pagerequest.isActive = false
    } else {
      this.pagerequest.isActive = null
    }
  }

  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L('¿Desea eliminar este tenant?'), {
        //title: this.L('Tips'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.L('Sí'),
        cancelTitle: this.L('No'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: 'tenant/delete',
            data: item
          })
          await this.getpage()
        }
      })
  }

  pageChange(page: number) {
    this.$store.commit('tenant/setCurrentPage', page)
    this.getpage()
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit('tenant/setPageSize', pagesize)
    this.getpage()
  }
  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize

    await this.$store.dispatch({
      type: 'tenant/getAll',
      data: this.pagerequest
    })
  }
  get pageSize() {
    return this.$store.state.tenant.pageSize
  }
  get totalCount() {
    return this.$store.state.tenant.totalCount
  }
  get currentPage() {
    return this.$store.state.tenant.currentPage
  }

  set currentPage(page) {
    this.$store.commit('tenant/setPageSize', page)
    this.getpage()
  }
  columns = [
    {
      title: this.L('Tenant'),
      dataIndex: 'tenancyName'
    },
    {
      title: this.L('Name'),
      dataIndex: 'name'
    },
    {
      title: this.L('Activo'),
       scopedSlots: { customRender: "active" },
    },
    {
      scopedSlots: { customRender: "actions" },
      title: "Acciones",
      fixed: "right",
    },
  ]
  async created() {
    this.getpage()
  }
  pagination: any = {};
  findModalShow: boolean = false;

  searchDefinition: {}[] = [
    {
      fieldName: "Nombre",
      fieldType: "text",
      fieldBind: "nombre",
      value: "",
    },
    {
      fieldName: "Activo",
      fieldType: "checkbox",
      fieldBind: "isActive",
      value: true,
    },
  ];

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit(
      "tenant/setCurrentPage",
      pagination.current
    );
    //this.getpage();

    await this.getpage();
  };

  async hide() {
    this.findModalShow = false;
  }
  async find() {
    this.findModalShow = true;
  }

  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value;
      } else {
        delete this.pagerequest[element.fieldBind];
      }
    });

    //this.pagerequest.idcampaña =;
    await this.getpage();
    this.findModalShow = false;
  };
}
